import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import toast from "react-hot-toast"
import SimpleReactValidator from "simple-react-validator"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import useScript from "../service/useScript"
import useDeviceDetect from "../service/useDeviceDetect"
import { acceptOnlyNumbers } from "../service/helper"

const BmiCalculator = ({ data: { allNodeTemplatePages: { edges } } }) => {
   
    const metaTag = [];
    const bannerData = edges[0]?.node?.relationships?.components[0];
    const bannerTitle = bannerData?.title || "";
    const bannerSubtitle = bannerData?.subtitle || "";
    const bannerImage = bannerData?.relationships?.bannerImage?.uri?.url;
    const mobileBannerImage = bannerData?.relationships?.mobilebannerImage?.uri?.url || bannerImage;
    const bannerAlt = typeof (bannerData?.bannerImage?.alt) !== "undefined" && bannerData?.bannerImage?.alt?.length > 0 ? bannerData?.bannerImage?.alt : "Hinduja Hospital"
    const detailData = edges[0]?.node?.relationships?.components[1];
    const title = detailData?.field_title || "";
    const description = detailData?.text?.processed || "";
    const list = edges[0]?.node?.relationships?.components[2]?.htmlText?.processed || "";
    useScript("/assets/js/custom_select.js");

    const [bmi, setBMI] = useState(null)
    const [weight, setWeight] = useState('')
    const [weightType, setWeightType] = useState('Kg')
    const [heightInCMs, setHeightInCMs] = useState('')
    const [heightInFTs, setHeightInFTs] = useState(null)
    const [heightInInchs, setHeightInInchs] = useState(null)

    const [, forceUpdateForRegister] = useState();
    useScript("/assets/js/login-register.js");
    const [validator] = useState(useRef(new SimpleReactValidator({
        element: (message, className) => < p className = "field_error" > {message} </p>}
    )))

    function resetData() {
        setBMI(null)
        setWeight('')
        setHeightInCMs('')
        setHeightInFTs(null)
        setHeightInInchs(null)
        validator.current.hideMessages()
        forceUpdateForRegister(2)
    }

    const convertWeight = (e) => {
        const { value } = e?.target
        setWeightType(value)
        if(weight && weightType !== value) {
            var kg = 0.45359237;
            if (value == "Lbs") {
                let val = Math.round((weight * 2.2) * 100) / 100;
                setWeight(val)
            }
            else {
                if (value == "Lbs") {
                    setWeight(Math.round(weight / kg))
                } else {
                    setWeight(Math.round(weight * kg))
                }
            }
        }
    }

    const handleHeightChange = (e) => {
        const { value, name } = e?.target
        if(name === 'ft') {
            setHeightInFTs(value)
            var ss = value * 12;
            var tot = ss ? ss : 0 + parseInt(heightInInchs);
            var val = tot * 2.54;
            setHeightInCMs(Math.round(val))
        } else if(name === 'inch') {
            setHeightInInchs(value)
            var ss = heightInFTs * 12;
            var tot = ss + parseInt(value);
            var val = tot * 2.54;
            setHeightInCMs(Math.round(val))
        } else {
            setHeightInCMs(value)
            var cm = Math.round(value / 2.54);
            var ft = parseInt(cm / 12);
            var inch = cm % 12;
            setHeightInFTs(ft ? ft : null)
            setHeightInInchs(inch.toString())
            if(value === '') setHeightInInchs(null)
        }
    }

    const handleSubmit = () =>{
        const result = validator.current.allValid()
        if (result === false) {
            validator.current.showMessages()
            forceUpdateForRegister(1)
        } else {
            if(weight <= 10 && weightType === 'Kg') {
                toast.error('Weight should be greater than 10kgs')
            } else if (weight <= 22 && weightType === 'Lbs') {
                toast.error('Weight should be greater than 22lbs')
            } else if (heightInCMs <= 33) {
                toast.error('Height should be taller than 33cms')
            } else {
                var h = heightInCMs / 100;
                var si = Math.round((weight / (h * h)) * 100) / 100;
                setBMI(custRound(si, 1))
                if (weightType == "Lbs") {
                    setBMI(Math.round((si / 2.2) * 100) / 100)
                }
            }
        }
    }

    function custRound(x, places) {
        return (Math.round(x * Math.pow(10, places))) / Math.pow(10, places)
    }

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [weight, weightType, heightInCMs, heightInFTs, heightInInchs])

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/accessibility.css",
                        ],
                    }}
                    tags={metaTag}
                />
                <main className="innerpage">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                            <img src={mobileBannerImage} alt={bannerAlt} className="d-md-none"/>
                            <img src={bannerImage} alt={bannerAlt} className="d-md-block d-none"/>
                        </picture>
                        <div className="banner_content text_left text_dark">
                            <div className="container">
                                <div className="bannerDescription">
                                    <h1>{bannerTitle ? bannerTitle : "BMI Calculator"}</h1>
                                    <p>{bannerSubtitle ? bannerSubtitle : "lorem ipsum valor diiliute"}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section_bg pt-first-section pb-section about-ages">
                        <div className="container">
                            <div className="section-head">
                                <h1>{title ? title : "BMI Calculator"}</h1>
                                <div dangerouslySetInnerHTML={{ __html: description ? description : "null" }} />
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="bmi-cal-wrapper form_block">
                                        <div id="divBMIEntry" className="rdCurves personalDetails">
                                            <div className="white_rounded_box">
                                                <div className="form_wrap">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-12 col-lg-7 borderStrip">
                                                            <div className="div_height">
                                                                <label>Height:</label>
                                                                <div className="row align-items-center">
                                                                    <div className="col-md-1 col-lg-2">
                                                                        <div className="form-group select-dd">
                                                                            <select name="ft" id="opt2" value={heightInFTs} onChange={handleHeightChange} placeholder={heightInFTs ? heightInFTs : 'Select'} className="form-control custom-select">
                                                                                <option name="feet" value="2">2'</option>
                                                                                <option name="feet" value="3">3'</option>
                                                                                <option name="feet" value="4">4'</option>
                                                                                <option name="feet" value="5">5'</option>
                                                                                <option name="feet" value="6">6'</option>
                                                                                <option name="feet" value="7">7'</option>
                                                                            </select>
                                                                            {validator.current.message('Ft', heightInFTs, 'required')} 
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 pr-0">
                                                                        Ft.
                                                                    </div>
                                                                    <div className="col-md-2 col-lg-2">
                                                                        <div className="form-group select-dd">
                                                                            <select name="inch" id="opt3" value={heightInInchs} onChange={handleHeightChange} placeholder={heightInInchs ? heightInInchs : 'Select'} className="form-control custom-select">
                                                                                <option name="inches" value="0">0"</option>
                                                                                <option name="inches" value="1">1"</option>
                                                                                <option name="inches" value="2">2"</option>
                                                                                <option name="inches" value="3">3"</option>
                                                                                <option name="inches" value="4">4"</option>
                                                                                <option name="inches" value="5">5"</option>
                                                                                <option name="inches" value="6">6"</option>
                                                                                <option name="inches" value="7">7"</option>
                                                                                <option name="inches" value="8">8"</option>
                                                                                <option name="inches" value="9">9"</option>
                                                                                <option name="inches" value="10">10"</option>
                                                                                <option name="inches" value="11">11"</option>
                                                                            </select>
                                                                            {validator.current.message('Inch', heightInInchs, 'required')} 
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1 pr-0">
                                                                        Inch
                                                                    </div>
                                                                    <div className="col-md-1 pr-0">
                                                                        OR
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="form-group mt-3">
                                                                            <input type="text" name="cms" value={heightInCMs} onChange={handleHeightChange} size="5" className="form-control cal_input" id="cmht" />
                                                                            {validator.current.message('cms', heightInCMs, 'required')} 
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <span className="midlabeltxt">Cms.</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div_width mt-4">
                                                                <label>Weight:</label>
                                                                <div className="row">
                                                                    <div className="col-md-2 col-6">
                                                                        <div className="form-group mt-3">
                                                                            <input type="text" name="wg" size="10" value={weight} onChange={(e) => setWeight(acceptOnlyNumbers(e?.target?.value))} className="form-control inputs" id="kwt" />
                                                                            {validator.current.message('weight', weight, 'required')} 
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-6">
                                                                        <div className="form-group select-dd">
                                                                            <select name="opt1" id="opt1" value={weightType} placeholder={weightType ? weightType : 'Select'} onChange={convertWeight} className="form-control custom-select">
                                                                                <option value="Kg">Kg</option>
                                                                                <option value="Lbs">Lbs</option>
                                                                            </select>
                                                                            {validator.current.message('weightType', weightType, 'required')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 col-lg-5">
                                                            <div className="mt-4 btns_wrap">
                                                                <button className="btn btn-primary mr-2" onClick={() => handleSubmit()}>Calculate</button>
                                                                <button className="btn btn-primary" onClick={() => resetData()}>Reset</button>
                                                            </div>
                                                            {bmi 
                                                            ? <div className="bmi_output mt-4">
                                                                <div class="rdCurves personalDetails2" id="divBMIResult">
                                                                    <div class="topBMIResultRow">
                                                                        <div class="leftTitleText">
                                                                            <p><strong>Estimated Body Mass Index (BMI) :</strong></p>
                                                                        </div>	
                                                                        <div class="resultsDv">
                                                                            <div class="results" id="si">{bmi}</div>
                                                                            <div class="overW"></div>
                                                                            <div class="CL"></div>
                                                                        </div>
                                                                    </div>	
                                                                </div>
                                                            </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div dangerouslySetInnerHTML={{ __html: list ? list : "null" }} className="mt-4" />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export const query = graphql`
query BMICalculator {

    allNodeTemplatePages(filter: {path: {alias: {regex: "/bmi-calculator$/"}}}) {
  
      edges {
  
        node {
  
          id
  
          title
  
          path {
  
            alias
  
          }
  
          metatag {
            attributes {
                content
                href
                name
                property
                rel
            }
          }
  
          relationships {
  
           components: field_component_type {
  
              __typename
  
              ...ParagraphBanner
  
              ...ParagraphTitleAndDescription
  
              ...ParagraphHTMLText
  
            }
  
          }
  
        }
  
      }
  
    }
  
  }`

export default BmiCalculator